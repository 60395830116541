import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Container/Container.scss';

class Container extends Component {
  static propTypes = {
    form: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    form: false,
    className: '',
  };

  state = {};

  render() {
    const { children, className, form } = this.props;
    return (
      <div
        className={classNames(className, styles.container, 'containerGeneral', {
          [styles.form]: form,
        })}
      >
        {children}
      </div>
    );
  }
}

export { Container as default };
